import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Create React App: Runtime vs Buildtime Environment Variables",
  "minRead": 7,
  "date": "2019-08-13T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`This post will show you how I migrate create-react-app custom environment variables from buildtime to be configurable at runtime.
According to create-react-app `}<a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/adding-custom-environment-variables"
      }}>{`docs`}</a>{`, we can not embed environement variables during runtime.
so defining them with the prefix `}<inlineCode parentName="p">{`REACT_APP_`}</inlineCode>{` will expose them only in build-time where you can access them by `}<inlineCode parentName="p">{`process.env.REACT_APP_KEY`}</inlineCode></p>
    <p>{`Note: I will use these aliases throughout the rest of the article:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`env vars`}</strong>{` instead of environment variables`}</li>
      <li parentName="ul"><strong parentName="li">{`env/s`}</strong>{` instead of enviroment/s`}</li>
    </ul>
    <h2>{`Initializing Environment Variables in Buildtime`}</h2>
    <p>{`To define env vars in buildtime, just put your own env vars keys into the `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file located in your home path, and prefixed by `}<inlineCode parentName="p">{`REACT_APP_`}</inlineCode>{`.
The env vars in this file will be exposed to your JS code in the development environment only.
There are more configurable ways to define env vars in test, production envs, and even override all the three envs
locally on your machine as described in create-react-app `}<a parentName="p" {...{
        "href": "https://create-react-app.dev/docs/adding-custom-environment-variables"
      }}>{`docs`}</a>{` as the following:`}</p>
    <ul>
      <li parentName="ul">{`test env > add the env vars in `}<inlineCode parentName="li">{`.env.test`}</inlineCode></li>
      <li parentName="ul">{`prodcution env > add the env vars in `}<inlineCode parentName="li">{`.env.production`}</inlineCode></li>
      <li parentName="ul">{`locally-test-override env > add env vars in `}<inlineCode parentName="li">{`.env.local.test`}</inlineCode></li>
      <li parentName="ul">{`locally-production-override > add env vars in `}<inlineCode parentName="li">{`.env.local.production`}</inlineCode></li>
      <li parentName="ul">{`locally-development-override > add env vars in `}<inlineCode parentName="li">{`.env.local.development`}</inlineCode></li>
    </ul>
    <p>{`There is something to be considered; `}<inlineCode parentName="p">{`.env`}</inlineCode>{` files have precedence rules based on which npm/yarn command you will run as the following:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`yarn/npm start`}</inlineCode>{`: `}<inlineCode parentName="li">{`.env.development.local`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env.development`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env.local`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn/npm build`}</inlineCode>{`: `}<inlineCode parentName="li">{`.env.production.local`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env.prodcution`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env.local`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`yarn/npm test`}</inlineCode>{`: `}<inlineCode parentName="li">{`.env.test.local`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env.test`}</inlineCode>{`, `}<inlineCode parentName="li">{`.env`}</inlineCode></li>
    </ul>
    <p>{`when the precedence is from the left to the right.`}</p>
    <p>{`Note: `}<inlineCode parentName="p">{`.env.local`}</inlineCode>{` is missing from `}<inlineCode parentName="p">{`yarn/npm test`}</inlineCode>{` and don't know why! but it is mentioned in create-react-app docs!`}</p>
    <p>{`A final example will be a `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file with env vars keys and values like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`   REACT_APP_KEY = <some-value>;
`}</code></pre>
    <h2>{`Migrate to Runtime Environment Variables`}</h2>
    <p>{`This is a hack solution because the definition of env vars in browsers does not even exist. It is something like a fake abstraction as mentioned `}<a parentName="p" {...{
        "href": "https://www.freecodecamp.org/news/how-to-implement-runtime-environment-variables-with-create-react-app-docker-and-nginx-7f9d42a91d70/"
      }}>{`here`}</a>{`.
We will keep our `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file only with the default development env vars as it is our main concern.`}</p>
    <h3>{`How this will be done?`}</h3>
    <p>{`I extract the env vars in `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file into a new javascript config file using a simple bash script as below imported from `}<a parentName="p" {...{
        "href": "https://www.freecodecamp.org/news/how-to-implement-runtime-environment-variables-with-create-react-app-docker-and-nginx-7f9d42a91d70/"
      }}>{`here`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#!/bin/bash

set -e

# Define env-config path
env_config_path=./env-config.js


# Recreate config file
rm -rf $env_config_path
touch $env_config_path

# Add assignment
echo "window._env_ = {" >> $env_config_path

# Read each line in .env file
# Each line represents key=value pairs
while read -r line || [[ -n "$line" ]];
do

# Split env variables by character \`=\`
if printf '%s\\n' "$line" | grep -q -e '='; then

    varname=$(printf '%s\\n' "$line" | sed -e 's/=.*//')

    varvalue=$(printf '%s\\n' "$line" | sed -e 's/^[^=]*=//')

fi

# Read value of current variable if exists as Environment variable
value=$(printf '%s\\n' "\${!varname}")

# Otherwise use value from .env file
[[ -z $value ]] && value=\${varvalue}

# Append configuration property to JS file
echo "  $varname: \\"$value\\"," >> $env_config_path
done < .env

echo "}" >> $env_config_path
`}</code></pre>
    <br></br>
    <p>{`This will create as `}<inlineCode parentName="p">{`env-config.js`}</inlineCode>{` file that you will need to embed like below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<script src="%PUBLIC_URL%/env-config.js"></script>
`}</code></pre>
    <br></br>
    <p>{`into your `}<inlineCode parentName="p">{`public/index.html`}</inlineCode>{`.`}</p>
    <p>{`And, this is how the file will look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.__env__ = {
  REACT_APP_KEY: value,
};
`}</code></pre>
    <h3>{`Putting All Together`}</h3>
    <p>{`To get all of these running in development, you will need to:`}</p>
    <p>{`• update `}<inlineCode parentName="p">{`yarn/npm start`}</inlineCode>{` command to`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./env.sh && ./env.sh && cp env-config.js ./public/ && react-scripts start
`}</code></pre>
    <p>{`• replace all`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`process.env.REACT_APP_KEY;
`}</code></pre>
    <p>{`by`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.__env__.REACT_APP_KEY;
`}</code></pre>
    <h3>{`Setup Tests Environment Variables`}</h3>
    <p>{`Doing all of the above will not guarantee running your test suites correctly, actually, you will see an error
that can't read property `}<inlineCode parentName="p">{`REACT_APP_KEY`}</inlineCode>{` of undefined. This is because `}<inlineCode parentName="p">{`__env__`}</inlineCode>{`
is not exposed in the test environment which by the way is a build-time process. There is a hack solution
for this problem which is reusing `}<inlineCode parentName="p">{`.env`}</inlineCode>{` file to assign all the values from it to `}<inlineCode parentName="p">{`__env__`}</inlineCode>{`
into your `}<inlineCode parentName="p">{`setupTests.js`}</inlineCode>{` like below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`window.__env__ = {
  // highlight-start
  REACT_APP_KEY: process.env.REACT_APP_KEY,
  // highlight-end
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      